import React from 'react';
import PropTypes from 'prop-types';

import Table from 'cccisd-graphql-table';
import Notification from 'cccisd-notification';
import { Report, PrintOnly, Page } from 'cccisd-laravel-report';
import { client as apollo, gql } from 'cccisd-apollo';

import logQuery from './acitivtyLog.graphql';
import style from './style.css';
import otherStyle from '../style.css';
import ArrowSVG from '../NeedsAttention_Strength-Arrow.svg';

import _isEqual from 'lodash/isEqual';
import format from 'date-fns/format';
import _get from 'lodash/get';

export default class Component extends React.Component {
    static propTypes = {
        /**
         * content json for view
         */

        determineSection: PropTypes.func,
        showPastSurvey: PropTypes.func,
        goals: PropTypes.object,
        devTags: PropTypes.object,

        metricsQuery: PropTypes.string,
        rows: PropTypes.array,
        renderNotes: PropTypes.func,
        renderTableSection: PropTypes.func,
        processGoals: PropTypes.func,
        processCheckUpData: PropTypes.func,
        getGoalsFromProps: PropTypes.func,
        renderPrintHeader: PropTypes.func,
        closeExpandables: PropTypes.func,
        user: PropTypes.object.isRequired,
    };

    state = {
        currentPawn: null,
        user: null,
        pawnId: null,
        goals: {},
        devTags: {},
        notes: {},
    };

    componentDidUpdate(prevProps) {
        if (!_isEqual(prevProps, this.props)) {
            this.loadData();
        }
    }

    loadData = async () => {
        const promises = [this.table.loadData()];
        await Promise.all(promises);
    };

    handleClick = async row => {
        const pawnId = row.targetId;
        const deploymentId = JSON.parse(row.metadata).deploymentId;
        const { user, metricsQuery } = this.props;
        const selectedUser = this.props.user;
        const hashArr = selectedUser.name.split('--');

        const metrics = await apollo.query({
            query: gql`
                ${metricsQuery}
            `,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
                deploymentId: deploymentId ? deploymentId : null,
                assignmentId: selectedUser.assignmentId ? selectedUser.assignmentId : null,
                parentAssignmentProgressId: user.assignmentProgressId ? user.assignmentProgressId : null,
            },
        });
        const hasTeacherView = metrics?.data?.roles?.anyRole?.teacherView?.completedDate;

        const assignmentProgress = metrics.data.roles.anyRole.assignmentProgress;
        const devTags = hasTeacherView ? metrics.data.roles.anyRole.teacherView.devTags : assignmentProgress.devTags;
        const timestamps = assignmentProgress.variableTimestamps;
        const reportShared = devTags.ShareReport === '1' ? true : false;

        const notes = assignmentProgress.notes;
        const goalsMetrics = assignmentProgress.goals;
        const notesMetrics = metrics.data.metrics.variableList;
        const initialGoals = this.props.processGoals(goalsMetrics);
        const propGoals = this.props.getGoalsFromProps();
        const completedDate = format(assignmentProgress.completedDate, 'MM/DD/YYYY');
        const devTagArr = Object.keys(devTags).map(item => {
            3;

            return {
                value: devTags[item],
                key: item,
            };
        });

        const noDevTags = devTagArr.filter(item => item.value !== 'MetricsDevTags').every(item => item.value === null);

        this.props.closeExpandables();

        this.setState(
            {
                user,
                username: selectedUser.label,
                currentPawn: {
                    pawnId: selectedUser.value,
                    pawnHash: hashArr[0],
                    respondentHash: hashArr[1],
                },
                initialGoals: initialGoals ? initialGoals : propGoals,
                goals: initialGoals ? initialGoals : propGoals,
                notes,
                notesMetrics,
                devTags,
                loading: false,
                timestamps,
                completedDate,
                noDevTags,
                shareReport: reportShared,
            },
            () => {
                this.download();
            }
        );
    };

    download = () => {
        this.report.download();
        Notification({
            message: 'Please wait a moment while your PDF is prepared',
            type: 'info',
            duration: 5000,
        });
    };

    renderCheckupTable = () => {
        const { rows, processCheckUpData, renderTableSection } = this.props;

        const tableData = processCheckUpData(rows, this.state);
        // Separate Table Data
        const structureTable = tableData.slice(0, 5);
        const instructionTable = tableData.slice(5, 11);
        const behaviorTable = tableData.slice(11, 18);
        const climateTable = tableData.slice(18, 23);

        return (
            <div className={otherStyle.tableWrapper}>
                <table className={`${otherStyle.table} table`}>
                    <thead className={otherStyle.tableHead}>
                        <tr className="row">
                            <th style={{ border: 'none', backgroundColor: '#fff', width: '3.5%' }} />
                            <th className={`text-center ${otherStyle.goalCol}`}>Goal</th>
                            <th className={`text-center ${otherStyle.notesCol}`}>Notes</th>
                            <th className={`text-center ${otherStyle.areaCol}`}>Area to Improve</th>
                            <th
                                colSpan="6"
                                style={{
                                    whiteSpace: 'nowrap',
                                    borderTopRightRadius: '15px',
                                    borderRight: 'none',
                                }}
                            >
                                <div className={otherStyle.arrowBox}>
                                    <span>Needs Attention</span>
                                    <img src={ArrowSVG} style={{ width: '45%' }} alt="Arrow goes here." />
                                    <span>Strength</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableSection(structureTable, 0)}
                        {renderTableSection(instructionTable, 5)}
                        {renderTableSection(behaviorTable, 11)}
                        {renderTableSection(climateTable, 18)}
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        const { user } = this.props;
        const { completedDate } = this.state;
        return (
            <div>
                <h3 className={style.title}>Activity Log</h3>
                <Table
                    ref={element => {
                        this.table = element;
                    }}
                    perPage={15}
                    key={user.pawnId}
                    query={logQuery}
                    rowKey="eventId"
                    graphqlVariables={{
                        teacherId: +user.value,
                    }}
                    columns={[
                        {
                            name: 'eventDate',
                            label: 'Last Updated',
                            className: 'text-center',
                            width: '10%',
                            render: ({ value, row }) => {
                                if (value) {
                                    const date = format(value, 'MM/DD/YYYY');
                                    return <span>{date}</span>;
                                }
                            },
                        },
                        {
                            name: 'eventType',
                            label: 'Item Created',
                            render: ({ value, row }) => {
                                const { rows, determineSection, devTags } = this.props;

                                if (value === 'feedbackSurveyCompleted') {
                                    return <span style={{ fontWeight: 'bold' }}>Feedback Survey Completed</span>;
                                }
                                if (value === 'feedbackSurveyStarted') {
                                    return <span style={{ fontWeight: 'bold' }}>Feedback Survey Started</span>;
                                }
                                const data = JSON.parse(row.metadata);
                                if (value === 'unlinkCoach') {
                                    return (
                                        <span style={{ fontWeight: 'bold' }}>
                                            Unlinked with coach: {data.coach.name}
                                        </span>
                                    );
                                }

                                if (value === 'linkCoach') {
                                    return (
                                        <span style={{ fontWeight: 'bold' }}>Linked with coach: {data.coach.name}</span>
                                    );
                                }
                                if (value === 'linkAdmin') {
                                    return (
                                        <span style={{ fontWeight: 'bold' }}>Linked with admin: {data.admin.name}</span>
                                    );
                                }
                                if (value === 'unlinkAdmin') {
                                    return (
                                        <span style={{ fontWeight: 'bold' }}>
                                            Unlinked with admin: {data.admin.name}
                                        </span>
                                    );
                                }
                                if (value === 'planningSurveyStarted') {
                                    return <span style={{ fontWeight: 'bold' }}>Planning Survey Started</span>;
                                }
                                if (value === 'planningSurveyCompleted') {
                                    return <span style={{ fontWeight: 'bold' }}>Planning Survey Completed</span>;
                                }

                                const currentRow = rows.find(item => item.goalTag === data.goal);
                                let label = '';
                                if (currentRow.isOther) {
                                    const otherLabel = devTags[currentRow.labelTag];
                                    label = (
                                        <span>
                                            <span>Other:</span>
                                            <span style={{ textDecoration: 'underline' }}> {otherLabel} </span>
                                        </span>
                                    );
                                } else {
                                    label = currentRow.label;
                                }

                                return (
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {value === 'goalRemoved' ? (
                                                <span style={{ minHeight: '40px' }}>Goal Removed: </span>
                                            ) : (
                                                <span className={style.minus}>Goal Added: </span>
                                            )}
                                        </span>{' '}
                                        <span> {label} | </span>
                                        <span style={{ fontStyle: 'italic' }}>{determineSection(data.row)}</span>
                                    </div>
                                );
                            },
                        },
                        {
                            name: 'pawn.pawn.roleName',
                            label: 'Updated By',
                            className: 'text-center',
                            width: '10%',
                        },
                        {
                            name: '',
                            label: 'Actions',
                            className: 'text-center',
                            width: '25%',
                            render: ({ value, row, extraData }) => {
                                if (row.eventType === 'feedbackSurveyCompleted') {
                                    const eventType = _get(row, 'eventType');
                                    const progressList = _get(row, 'pawn.assignmentProgressList');
                                    const deploymentId = JSON.parse(row.metadata).deploymentId;

                                    const match = progressList.find(
                                        item => +item.deployment.deploymentId === +deploymentId
                                    );

                                    if (!match) {
                                        const otherList = _get(
                                            row,
                                            'pawn.descendantRoles.teacher.assignmentProgressList'
                                        );
                                        const otherMatch = otherList.find(
                                            item => +item.deployment.deploymentId === +deploymentId
                                        );

                                        if (otherMatch) {
                                            const submittedByTeacher =
                                                +otherMatch.devTags.SubmittedBy === +row.targetId;
                                            if (otherMatch.devTags.ShareReport === '1' || submittedByTeacher) {
                                                return (
                                                    <>
                                                        {eventType === 'feedbackSurveyCompleted' ? (
                                                            <button
                                                                type="button"
                                                                className={`btn ${style.actionButton}`}
                                                                onClick={() => {
                                                                    this.handleClick(row);
                                                                }}
                                                            >
                                                                View Feedback Form
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                );
                                            }
                                        }
                                    }
                                    if (match) {
                                        const submittedByTeacher = +match.devTags.SubmittedBy === +row.targetId;
                                        if (match.devTags.ShareReport === '1' || submittedByTeacher) {
                                            return (
                                                <>
                                                    {eventType === 'feedbackSurveyCompleted' ? (
                                                        <button
                                                            type="button"
                                                            className={`btn ${style.actionButton}`}
                                                            onClick={() => {
                                                                this.handleClick(row, extraData);
                                                            }}
                                                        >
                                                            View Feedback Form
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            );
                                        }
                                    }
                                }
                            },
                        },
                    ]}
                />
                <div className={otherStyle.printWrapper}>
                    {this.state.user && (
                        <PrintOnly>
                            <Report
                                width="100%"
                                height="100%"
                                showPagination={false}
                                onRef={element => {
                                    this.report = element;
                                }}
                                downloadFilename={`Check-UpFeedbackReport_${user.label}_${completedDate}`}
                            >
                                <Page>
                                    {this.props.renderPrintHeader(this.state)}
                                    {this.renderCheckupTable()}
                                </Page>
                                <Page>{this.props.renderNotes(this.state)}</Page>
                            </Report>
                        </PrintOnly>
                    )}
                </div>
            </div>
        );
    }
}
